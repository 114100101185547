.App {
  text-align: center;
  background-color: #4f4f4f;
  min-height: 100vh;
  height: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Roboto Condensed, sans-serif';
  font-size: 14px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999999;
  box-sizing: border-box;
}

.modal > .modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal > .modal-close {
  position: absolute;
  right: 15px;
  top: 10px;
  color: #5e5e5e;
  cursor: pointer;
  font-size: 1.25em;
  padding: 7px;
  background: rgba(255, 255, 255, 0.749);
  border: 1px solid #c3c0c0;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
}

.modal > .modal-close:hover {
  background: rgba(255, 255, 255, 0.989);
}

.modal > .modal-body {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: #303030;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  color: #c3c0c0;
}
